import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Plx from "react-plx"
import windowSize from "react-window-size"

import Stars from "../../components/blog/stars"
import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Metaball from "../../components/metaball"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Title from "../../components/hero/title"
import RevealOnScroll from "../../components/reveal-on-scroll"
import { defaultPadding, guidePaddingBottom } from "../../utils/styles"
import Pagination from "../../components/blog/pagination"
import BlogPostPreviewItem from "../../components/blog/blog-post-preview-item"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

class BlogListPage extends React.Component {
  render() {
    const { allWpPost } = this.props.data
    const { numPages, currentPage } = this.props.pageContext
    const clientHeight = this.props.windowHeight || 1

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO
          title="GatsbyJS Blog"
          description="Case studies, stories, and releases from the Gatsby team and community"
          url={this.props.location.href}
        />
        <Header />
        <main css={{ position: `relative`, width: `100%` }}>
          <SkipNavTarget />
          <Plx
            // animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  {
                    endValue: 200,
                    property: `translateY`,
                    startValue: 0,
                  },
                ],
                start: `self`,
              },
            ]}
            css={{ position: `relative` }}
          >
            <Metaball />
          </Plx>
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: clientHeight,
                properties: [
                  {
                    endValue: 500,
                    property: `translateY`,
                    startValue: 0,
                  },
                  {
                    endValue: 0,
                    property: `opacity`,
                    startValue: 1,
                  },
                ],
                start: `self`,
              },
            ]}
            css={{ position: `relative` }}
          >
            <Stars />
          </Plx>
          <HeroContainer>
            <Container
              css={theme => ({
                [theme.mediaQueries.tablet]: {
                  ...defaultPadding,
                },
              })}
            >
              <div
                css={{
                  position: `relative`,
                  zIndex: 100,
                  width: `100%`,
                  maxWidth: `36rem`,
                  display: `flex`,
                  justifyContent: `space-between`,
                }}
              >
                <Title
                  css={theme => ({
                    marginBottom: 0,
                    color: theme.colors.gatsby,
                  })}
                >
                  Blog
                </Title>
                <Link to="/blog/tags/">View All Tags</Link>
              </div>
            </Container>
          </HeroContainer>
          <div
            css={theme => ({
              paddingTop: theme.space[4],
              overflow: `hidden`,
              position: `relative`,
              ...guidePaddingBottom,
            })}
          >
            {allWpPost && (
              <Container
                css={theme => ({
                  [theme.mediaQueries.tablet]: {
                    ...defaultPadding,
                  },
                })}
              >
                <RevealOnScroll>
                  <div
                    css={theme => ({
                      paddingTop: theme.space[5],
                      position: `relative`,
                      zIndex: 1,
                    })}
                  >
                    {allWpPost.nodes.map(post => {
                      return <BlogPostPreviewItem post={post} key={post.slug} />
                    })}
                    <Pagination numPages={numPages} currentPage={currentPage} />
                  </div>
                </RevealOnScroll>
              </Container>
            )}
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

BlogListPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  windowHeight: PropTypes.number,
}

export default windowSize(BlogListPage)

export const pageQuery = graphql`
  query BlogListPage($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        ...BlogPostPreviewInformation
      }
    }
  }
`
