import React, { Fragment } from "react"
import { Global, css } from "@emotion/core"
import InViewMonitor from "react-inview-monitor"

const RevealOnScroll = ({ children }) => (
  <Fragment>
    <Global
      styles={css`
        .vis-hidden {
          visibility: hidden;
        }

        .animated {
          -webkit-animation-duration: 0.35s;
          animation-duration: 0.35s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            -webkit-transform: translate3d(0, 10%, 0);
            transform: translate3d(0, 10%, 0);
          }
          to {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
          }
        }

        .fadeInUp {
          -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
        }
      `}
    />
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView="animated fadeInUp"
    >
      {children}
    </InViewMonitor>
  </Fragment>
)

export default RevealOnScroll
