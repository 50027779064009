import React from "react"
import { Link } from "gatsby"

const PaginationLink = ({ to, children, style, ...props }) => {
  if (to) {
    return (
      <Link to={to} css={style} {...props}>
        {children}
      </Link>
    )
  }
  return (
    <span css={theme => [style, { color: theme.colors.text.secondary }]}>
      {children}
    </span>
  )
}

export default PaginationLink
