/* eslint-disable jsx-a11y/no-onchange */
import React from "react"
import { navigate } from "gatsby"
import {
  MdArrowForward as ArrowForwardIcon,
  MdArrowBack as ArrowBackIcon,
} from "react-icons/md"
import PaginationLink from "./PaginationLink"

const prevNextLinkCss = theme => ({
  borderBottom: 0,
  color: theme.colors.gatsby,
  textDecoration: `none`,
  lineHeight: theme.lineHeights.body,
  svg: {
    position: `relative`,
    top: `3px`,
  },
})

const outerWrapperCss = theme => ({
  display: `flex`,
  justifyContent: `space-between`,
  flexDirection: `column`,
  fontSize: theme.fontSizes[1],
  [theme.mediaQueries.md]: {
    background: `transparent`,
    borderTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    flexDirection: `row`,
  },
})

const innerWrapperCss = theme => ({
  display: `flex`,
  margin: `0`,
  padding: `0`,
  justifyContent: `flex-end`,
  alignItems: `center`,
  marginBottom: theme.space[3],
  fontSize: theme.fontSizes[2],
  [theme.mediaQueries.md]: {
    width: `15rem`,
    marginBottom: 0,
  },
})

class Pagination extends React.Component {
  changePage = e => {
    navigate(e.target.value ? `/blog/page/${e.target.value}` : `/blog/`)
  }
  render() {
    const { numPages, currentPage } = this.props
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPageNum =
      currentPage - 1 === 1 ? `` : `page/${(currentPage - 1).toString()}`
    const nextPageNum = (currentPage + 1).toString()
    const prevPageLink = isFirst ? null : `/blog/${prevPageNum}`
    const nextPageLink = isLast ? null : `/blog/page/${nextPageNum}`

    return (
      <div css={outerWrapperCss}>
        <div css={innerWrapperCss}>
          {prevPageLink && (
            <PaginationLink
              to={prevPageLink}
              css={theme => [prevNextLinkCss(theme), { marginRight: "auto" }]}
            >
              <ArrowBackIcon css={theme => ({ marginRight: theme.space[3] })} />
              Newer posts
            </PaginationLink>
          )}

          {nextPageLink && (
            <PaginationLink to={nextPageLink} css={prevNextLinkCss}>
              Older posts
              <ArrowForwardIcon
                css={theme => ({ marginLeft: theme.space[3] })}
              />
            </PaginationLink>
          )}
        </div>
        <div
          css={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `flex-end`,
          }}
        >
          <span>Showing page &nbsp;</span>
          <select
            aria-label="Pagination Dropdown"
            value={currentPage === 1 ? `` : currentPage.toString()}
            onChange={this.changePage}
            css={theme => ({
              appearance: `none`,
              border: `none`,
              padding: `0.5ch 2ch 0.5ch 0.5ch`,
              color: theme.colors.gatsby,
              fontWeight: `bold`,
            })}
          >
            {Array.from({ length: numPages }, (_, i) => (
              <option
                value={`${i === 0 ? `` : i + 1}`}
                key={`pagination-number${i + 1}`}
                aria-label={`Goto Page ${i + 1}`}
                aria-current={currentPage === i + 1}
              >
                {i + 1}
              </option>
            ))}
          </select>
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            css={theme => ({
              position: `relative`,
              right: 4,
              fill: theme.colors.gatsby,
              pointerEvents: `none`,
            })}
          >
            <path d="M0 0l5 4.998L10 0z" fillRule="evenodd" />
          </svg>
          <span>of &nbsp;</span>
          <span>{numPages}</span>
        </div>
      </div>
    )
  }
}

export default Pagination
